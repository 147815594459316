import { css } from '@emotion/react';

export const fadeAnimations = css`
  @keyframes fadeIn {
    from {
      opacity: 0;
      pointer-events: none;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      pointer-events: none;
    }
  }
`;

export const soldOutWrapperStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 120;
  width: 100%;
  height: 100%;
  transition: all 0.4s ease;
`;

export const clickable = css`
  cursor: pointer;
  line-height: 1;
  padding: 0;
  pointer-events: auto;
  text-align: center;
  white-space: nowrap;
`;
